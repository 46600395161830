import React, { useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Slide, LinearProgress } from '@mui/material';
import UploadDocuments from '../../../../components/UploadDocuments'
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormHelperText from "@mui/material/FormHelperText"
import { DatePickerComponent } from '../../../../components/DateTimePickerComponent';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddDocument({
    showGraduationDate,
    setShowGraduationDate,
    comment,
    setComment,
    name,
    isList,
    startDate,
    setStartDate,
    fileUrl,
    setFileUrl,
    setName,
    darkState,
    loading,
    files,
    setFiles,
    open,
    handleClose,
    handleAddDocument,
    selectError,
    newDocuments,
    setNewDocuments,
    documentType,
    setDocumentType,
    graduationDate,
    setGraduationDate
}) {
    const [documentTypes, setDocumentTypes] = useState([]);

    const handleStartDateChange = (e) => {
        setStartDate(e)
    }

    const handleGraduationDateChange = (e) => {
        setGraduationDate(e)
    }

    const handleChange = (e) => {
        const findDocumentTypeName = documentTypes.find((item) => item['@id'] === e.target.value)
        if (findDocumentTypeName.name === 'Diplôme') {
            setShowGraduationDate(true)
        } else {
            setShowGraduationDate(false)
            setGraduationDate(null)
        }
        setDocumentType(e.target.value);
    };

    return (
        <>
            <Dialog open={!!open} onClose={handleClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition} keepMounted>
                <DialogTitle id="form-dialog-title">{open.uuid ? 'Modifier' : 'Ajouter'} un document</DialogTitle>
                <DialogContent>
                    {!isList && <div style={{marginTop: 10}}>
                        <TextField
                            autoFocus
                            id="name"
                            label="Nom du document"
                            color={darkState ? 'secondary' : 'primary'}
                            style={{marginBottom: 30}}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <FormControl
                            color={darkState ? 'secondary' : 'primary'}
                            style={{width: 200, marginLeft: 40, marginRight: 10}}
                            required
                            error={!!selectError}
                        >
                            <InputLabel id="label">Type de document</InputLabel>
                            <Select
                                labelId="label"
                                id="select"
                                value={documentType || ''}
                                onChange={handleChange}
                            >
                                <MenuItem value="">
                                    <em>Sélectionner un type</em>
                                </MenuItem>
                                {documentTypes && documentTypes.map((doc) => (
                                    <MenuItem key={doc['@id']} value={doc['@id']}>
                                        {doc.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        <FormHelperText>{selectError}</FormHelperText>
                        </FormControl>
                        <DatePickerComponent
                            darkState={darkState}
                            color={!darkState && 'primary' }
                            handleDateChange={handleStartDateChange}
                            selectedDate={startDate}
                            text="Date de renouvellement *"
                        /><br />
                        {showGraduationDate &&
                        <FormControl style={{width: 200, marginLeft: 40, marginRight: 10}}>
                            <DatePickerComponent
                                darkState={darkState}
                                color={!darkState && 'primary' }
                                handleDateChange={handleGraduationDateChange}
                                selectedDate={graduationDate}
                                text="Date d'obtention du diplôme" 
                            /><br/>
                        </FormControl>}
                    </div>}
                    {open.uuid && <TextField
                        multiline
                        rows={4}
                        id="comment"
                        label="Commentaire"
                        color={darkState ? 'secondary' : 'primary'}
                        style={{marginBottom: 30, marginTop: 30, width: '100%'}}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />}
                    {!open.uuid && <div style={{marginTop: 40}}>
                        <UploadDocuments
                            setDocumentTypes={setDocumentTypes}
                            isList={isList}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            darkState={darkState}
                            fileUrl={fileUrl}
                            setFileUrl={setFileUrl}
                            files={files}
                            setFiles={setFiles}
                            error={selectError}
                            setDocuments={setNewDocuments}
                            documentType={documentType}
                            setDocumentType={setDocumentType}
                        />
                    </div>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={darkState ? 'secondary' : 'primary'}>
                        Annuler
                    </Button>
                    <Button
                        onClick={handleAddDocument}
                        disabled={!newDocuments.length && !open.uuid}
                        color={darkState ? 'secondary' : 'primary'}
                    >
                        {open.uuid ? 'Modifier' : 'Ajouter'}
                    </Button>
                </DialogActions>
            </Dialog>
            {loading && <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
        </>
    );
}
